<template>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <v-card>
      <v-card-title>
        Manage Sessions
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="4">
            <v-text-field
                outlined
                dense
                label="Title"
                v-model="academic_session.title"
                :error="$v.academic_session.title.$error"
            >
            </v-text-field>
            <span class="text-danger" v-if="$v.academic_session.title.$error">Session is required</span>
          </v-col>

          <v-col md="4">
            <v-menu
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                ref="menu"
                transition="scale-transition"
                v-model="menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formattedStartDate"
                    prepend-inner-icon="mdi-calendar"
                    :error="$v.academic_session.start_date.$error"
                    label="Start date"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Start date<span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.academic_session.start_date.$error">This Field is required</span>
              </template>
              <v-date-picker
                  no-title
                  @input="menu = false"
                  v-model="academic_session.start_date"
                  type="month"
                  :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col md="4">
            <v-menu
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                ref="menu"
                transition="scale-transition"
                v-model="menu1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formattedEndDate"
                    prepend-inner-icon="mdi-calendar"
                    label="End date"
                    :error="$v.academic_session.start_date.$error"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    dense
                >
                  <template v-slot:label>
                    End date<span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.academic_session.start_date.$error">This Field is required</span>
              </template>
              <v-date-picker
                  no-title
                  @input="menu1 = false"
                  :min="academic_session.start_date"
                  v-model="academic_session.end_date"
                  type="month"
              ></v-date-picker>
            </v-menu>
            <!--                                    <span academic-class="text-danger" v-if="$v.program.founded_date.$error">End Date Date is required</span>-->
          </v-col>

          <v-col md="4">
            <v-select
                :items="session_statuses"
                dense
                flat
                label="Status"
                v-model="academic_session.status"
                outlined
                item-text="title"
                item-value="value"
            >
            </v-select>
          </v-col>

<!--          <v-col md="4">-->
<!--            <v-switch-->
<!--                label="Is Recursion"-->
<!--                v-model="academic_session.is_recursion"-->
<!--            >-->
<!--            </v-switch>-->
<!--          </v-col>-->

          <v-col cols="12 text-right">
            <v-btn @click="closeDialog()"
            color="mr-2" text dark
            >Cancel
            </v-btn>
            <v-btn
                :loading="loading"
                @click="createOrUpdate"
                color="ml-2 btn btn-primary"
                medium
            >{{ edit ? 'Update' : 'Save' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import AcademicProgramSessionService from "@/services/academic/program/session/AcademicProgramSessionService";

const academicProgramSessionService = new AcademicProgramSessionService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      edit: false,
      menu: false,
      menu1: false,
      loading: false,
      isLoading: false,
      academic_session: {
        start_date: null,
        end_date: null,
        status: 'open',
        title: null
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      session_statuses: [
        {title: 'Open Now', value: 'open'},
        {title: 'Likely Open', value: 'scheduled_to_open'},
        {title: 'Closed', value: 'closed'},
      ],
    }
  },
  validations: {
    academic_session: {
      title: {required},
      start_date: {required},
      end_date: {required}
    }
  },
  computed: {
    formattedStartDate() {
      return this.academic_session.start_date ? this.$moment(this.academic_session.start_date).format('MMM YYYY') : '';
    },
    formattedEndDate() {
      return this.academic_session.end_date ? this.$moment(this.academic_session.end_date).format('MMM YYYY') : '';
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.edit = false
    },
    createSession() {
      this.dialog = true;
      this.edit = false
      this.resetForm();
    },
    editSession(item) {
      this.dialog = true;
      this.edit = true;
      this.resetForm();
      this.academic_session = item
    },

    createOrUpdate() {
      this.$v.academic_session.$touch()
      if (this.$v.academic_session.$error) {
        setTimeout(() => {
          this.$v.academic_session.$reset()
        }, 3000)
        return
      }
      if (!this.edit) {
        this._create();
      }
      else {
        this.update();
      }
    },

    _create: function () {
      this.loading = true;
      academicProgramSessionService
          .create(this.academic_session)
          .then(response => {
            this.$snotify.success("Session created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.errors = [];
            this.loading = false;
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(this.loading = false)
    },

    update: function () {
      this.loading = true;
      academicProgramSessionService
          .update(this.academic_session.id, this.academic_session)
          .then(response => {
            this.$snotify.success("Session updated successfully");
            this.$emit('refresh');
            this.resetForm();
            this.closeDialog();
            this.errors = [];
            this.loading = false;
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(this.loading = false)
    },

    resetForm() {
      this.academic_session = {
        start_date: null,
        end_date: null,
        status: 'open',
        title: null
      };
      this.$v.$reset();
    },

  }
}
</script>
