<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Program Session</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Program Session
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('academic-program', 'create')"
                      @click="createSession()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon elevation="2" outlined small>fas fa-plus</v-icon>&nbsp;
                    Add Program Session
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                      dense
                      label="Title"
                      outlined
                      v-model="search.title"
                      v-on:keyup.enter="__search"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-menu
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      ref="menu"
                      transition="scale-transition"
                      v-model="menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          prepend-inner-icon="mdi-calendar"
                          label="Start date"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                      >
                        <template v-slot:label>
                          Start date<span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                        no-title
                        @input="menu = false"
                        v-model="search.start_date"
                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col md="3">
                  <v-menu
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      ref="menu"
                      transition="scale-transition"
                      v-model="menu1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          prepend-inner-icon="mdi-calendar"
                          label="Start date"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                      >
                        <template v-slot:label>
                          End date<span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                        no-title
                        @input="menu1 = false"
                        :min="search.start_date"
                        v-model="search.end_date"
                    ></v-date-picker>
                  </v-menu>
                  <!--                                    <span academic-class="text-danger" v-if="$v.program.founded_date.$error">End Date Date is required</span>-->
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                      :items="session_statuses"
                      @input="search.status = $event !== null ? $event : ''"
                      clearable
                      dense
                      item-text="name"
                      item-value="value"
                      label="Status"
                      outlined
                      v-model="search.status"
                      v-on:keyup.enter="__search"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-btn
                      :loading="loading"
                      @click.prevent="__search"
                      class="mt-1 btn btn-primary float-right"
                  >
                    <v-icon elevation="2" outlined small>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th><strong>Session Name</strong></th>
                  <th><strong>Session Start Date</strong></th>
                  <th><strong>Session End Date</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="item in sortedItems()" :key="item.id">
                    <td class="px-2">
                      <a @click="editSession(item)" class="font-weight-bolder mb-1 font-size-lg">{{ item.title }}</a>
                    </td>
                    <td class="px-2">
                        {{ item.formatted_start_date ? item.formatted_start_date : 'NA' }}
                    </td>
                    <td class="px-2">
                      {{item.formatted_end_date ? item.formatted_end_date : 'NA' }}
                    </td>
                    <td>
                      <span class="text-uppercase text-xl-body-2 badge badge-primary" :class="{'badge-danger': item.status == 'closed', 'badge-info' : item.status == 'closed'}">{{replaceUnderScore(item.status)}}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250spx">

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="editSession(item)">
                                                    <span class="navi-icon">
                                                        <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                                    </span>
                                <span class="navi-text">Edit Session</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="deleteSession(item.id)">
                                                    <span class="navi-icon">
                                                        <v-icon color="red">fas fa-trash</v-icon>
                                                    </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems().length == 0">
                    <td colspan="5" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

      <create-and-update ref="create-and-update" @refresh="__getAcademicSessions"></create-and-update>
  </v-app>
</template>

<script>
import AcademicProgramSessionService
  from "@/services/academic/program/session/AcademicProgramSessionService";
import CreateAndUpdate from "./CreateAndUpdate";

const academicProgramSessionService = new AcademicProgramSessionService();
export default {
  name: "Sessions",
  components: {CreateAndUpdate},
  props: ['program'],

  data() {
    return {
      loading: false,
      academic_sessions: [],
      total: null,
      menu: false,
      menu1: false,
      perPage: null,
      page: null,
      search: {
        title: '',
        start_date: '',
        end_date: '',
        status: 'open'
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      sort: {
        key: '',
        isAsc: false
      },
      session_statuses: [
        {name: 'Open Now', value: 'open'},
        {name: 'Likely Open', value: 'scheduled_to_open'},
        {name: 'Closed', value: 'closed'},
      ],
    }
  },

  mounted() {
    this.__getAcademicSessions();
  },

  methods: {
    createSession() {
      this.$refs['create-and-update'].createSession();
    },

    editSession(item) {
      this.$refs['create-and-update'].editSession(item);
    },
    __search() {
      this.__getAcademicSessions();
    },
    __getAcademicSessions() {
      this.loading = true;
      academicProgramSessionService
          .paginate(this.search, this.page)
          .then(response => {
            this.academic_sessions = response.data.data;
            this.loading = false;
            this.sortedItems()
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          }).finally(this.loading = false)
    },
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortedItems() {
      const list = this.academic_sessions.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },

    deleteSession(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            academicProgramSessionService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Session deleted successfully");
                  this.loading = false;
                  this.__getAcademicSessions();
                })
                .catch(error => {
                  this.loading = false;
                  this.errors = error.errors;
                }).finally(this.loading = false)
          }
        }
      });
    },
    replaceUnderScore(status){
      return status.replaceAll("_", " ");
    }
  },

}
</script>

<style scoped>

</style>
